import { Building, Station } from "../../types";
import { CategoryHats, CategoryHoods, CategoryMount, CategoryTrousers } from "../../clothes";
import { LinenThread, LinenFabric, WoolThread, WoolFabric } from "../../resources/sewing";

export const SpinningWheel: Station = {
  name: "Spinning Wheel",
  recipes: [
    LinenThread,
    WoolThread,
  ]
}

export const Loom: Station = {
  name: "Loom",
  recipes: [
    LinenFabric,
    WoolFabric,
  ]
}

export const TailoringTable: Station = {
  name: "Tailoring Table",
  categories: [
    CategoryHats,
    CategoryHoods,
    CategoryTrousers,
    CategoryMount,
  ]
}

const SewingHut: Building = {
  name: "Sewing Hut",
  stations: [Loom, SpinningWheel, TailoringTable]
}
export default SewingHut