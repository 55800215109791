import { FlaxStalk } from "../../resources/sewing";
import { Building, Station } from "../../types";

export const ThreshingFloor: Station = {
  name: "Threshing Floor",
  recipes: [FlaxStalk],
}

const Barn: Building = {
  name: "Barn",
  stations: [ThreshingFloor]
}

export default Barn