

export type StorageType = "Resource Storage" | "Food Storage"

export interface Item {
  name: string,
  price: number,
  scheme?: number,
  weight: number,
  recipe?: RecipeItem[],
  amount?: number,
  storage?: StorageType,
  time?: number,
  durability?: number,
  technology?: { tech: Technology, amount: number }
}

export interface Plant extends Item { }
export interface Wood extends Item { }
export interface SewingItem extends Item { }
export interface SmithingItem extends Item { }
export interface AnimalItem extends Item { }



export type Technology = "Production"

export interface Location {
  name: Building,
  station: Station
}

export interface RecipeItem {
  item: Item,
  amount: number,
}

export interface Building {
  name: string,
  stations?: Station[],
  items?: Item[],
}

export interface Station {
  name: string,
  categories?: Category[],
  recipes?: Item[]
}

export interface Category {
  name: string,
  recipes: Item[],
}

export interface Skill {
  name: string,
  levels: number[]
}

export interface Data {
  building?: Building
  station?: Station
  category?: Category
  items?: Item[]
  item?: Item
  comparisonItems?: Item[]
  barter: number
  compactComparison: boolean
}

export const defaultData: Data = {
  building: undefined,
  station: undefined,
  category: undefined,
  items: undefined,
  item: undefined,
  comparisonItems: [],
  barter: 0,
  compactComparison: true,
}
