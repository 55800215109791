import { SewingItem } from "../types"

export const Flax: SewingItem = {
  name: "Flax",
  price: 6,
  weight: 0.02,
}
export const FlaxStalk: SewingItem = {
  name: "Flax Stalk",
  price: 3,
  weight: 0.2,
  recipe: [{
    item: Flax,
    amount: 2,
  }],
  amount: 2,
}
export const LinenThread: SewingItem = {
  name: "Linen Thread",
  price: 17,
  scheme: 50,
  weight: 0.1,
  time: 5000,
  storage: "Resource Storage",
  recipe: [{ item: FlaxStalk, amount: 5, }],
}
export const LinenFabric: SewingItem = {
  name: "Linen Fabric",
  price: 28,
  scheme: 50,
  weight: 0.1,
  time: 5000,
  recipe: [{ item: LinenThread, amount: 1, }],
  storage: "Resource Storage",
}


export const Wool: SewingItem = {
  name: "Wool",
  price: 6,
  weight: 0.02,
  storage: "Resource Storage",
}
export const WoolThread: SewingItem = {
  name: "Wool Thread",
  price: 70,
  time: 5000,
  recipe: [{ item: Wool, amount: 6 }],
  weight: 0.5,
  storage: "Resource Storage",
}
export const WoolFabric: SewingItem = {
  name: "Wool Fabric",
  price: 120,
  weight: 0.5,
  time: 5000,
  recipe: [{ item: WoolThread, amount: 6 }],
  technology: { tech: "Production", amount: 750 },
  storage: "Resource Storage",
}