const Headline = ({
  headline
}: {
  headline: string
}) => (
  <div className="flex items-center justify-start space-x-2">
    {false && <div className="bg-[url('../assets/divider.png')] bg-contain w-24 h-4" />}
    <div className="text-primary mb-1 mt-3">{headline}</div>
    <div className="bg-[url('../assets/divider.png')] bg-contain w-24 h-4 -scale-x-100" />
  </div>
)

export default Headline