
import { Leather } from "./resources/animal";
import { Log, Stick, Straw } from "./resources/resources";
import { LinenThread } from "./resources/sewing";
import { Plank } from "./resources/wood";
import { Item } from "./types";

// BOWS

export const Bow: Item = {
  name: "Bow",
  price: 70,
  scheme: 100,
  weight: 1.25,
  time: 10,
  durability: 100,
  recipe: [
    { item: Log, amount: 1 },
    { item: LinenThread, amount: 2 },
  ]
}

export const Longbow: Item = {
  name: "Longbow",
  price: 140,
  scheme: 500,
  weight: 1.5,
  time: 15,
  durability: 200,
  recipe: [
    { item: Log, amount: 1 },
    { item: LinenThread, amount: 4 },
    { item: Leather, amount: 1 },
  ]
}

export const RecurveBow: Item = {
  name: "Recurve Bow",
  price: 240,
  scheme: 1500,
  weight: 1.5,
  time: 18,
  durability: 300,
  recipe: [
    { item: Log, amount: 1 },
    { item: LinenThread, amount: 6 },
    { item: Leather, amount: 3 },
  ]
}

// CONTAINERS

export const Bucket: Item = {
  name: "Bucket",
  price: 6,
  scheme: 100,
  weight: 2,
  time: 18,
  durability: 100,
  recipe: [
    { item: Plank, amount: 2 },
  ],
  storage: "Resource Storage",
}

export const BucketOfWater: Item = {
  name: "Bucket of Water",
  price: 6,
  scheme: 100,
  weight: 4,
  time: 2,
  durability: 100,
  recipe: [
    { item: Bucket, amount: 1 },
  ],
  storage: "Food Storage",
}

export const Waterskin: Item = {
  name: "Waterskin",
  price: 40,
  weight: 0.25,
  time: 5,
  durability: 500,
  recipe: [
    { item: Leather, amount: 2 },
  ],
  storage: "Resource Storage",
}

export const WaterskinWithWater: Item = {
  name: "Waterskin with Water",
  price: 40,
  weight: 0.5,
  time: 5,
  durability: 500,
  recipe: [
    { item: Waterskin, amount: 1 },
  ],
  storage: "Food Storage",
}

// HANDHELDS

export const SimpleTorch: Item = {
  name: "Simple Torch",
  price: 3,
  weight: 0.5,
  time: 2,
  durability: 30,
  recipe: [
    { item: Stick, amount: 1 },
    { item: Straw, amount: 9 },
  ]
}

export const Torch: Item = {
  name: "Torch",
  price: 28,
  scheme: 250,
  weight: 0.5,
  time: 5,
  durability: 120,
  recipe: [
    { item: Stick, amount: 2 },
    { item: Leather, amount: 2 },
  ]
}