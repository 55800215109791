import { AnimalItem } from "../types"

export const Leather: AnimalItem = {
  name: "Leather",
  price: 8,
  weight: 0.25,
  storage: "Resource Storage",
}

export const Fur: AnimalItem = {
  name: "Fur",
  price: 4,
  weight: 0.25,
  storage: "Resource Storage",
}

export const Feather: AnimalItem = {
  name: "Feather",
  price: 0.9,
  weight: 0.01,
  storage: "Resource Storage",
}