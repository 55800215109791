import Barn from "./buildings/farming/barn"
import FarmShed from "./buildings/farming/farmShed"
import SewingHut from "./buildings/production/sewingHut"
import FishingHut from "./buildings/survival/fishingHut"
import HuntingLodge from "./buildings/survival/huntingLodge"
import { Building } from "./types"

export const buildings: Building[] = Array.prototype.concat(
  SewingHut,

  FishingHut,
  HuntingLodge,

  Barn,
  FarmShed,
)