import { DriedFishMeat, DriedMeat, FishMeat, Perch, Roach } from "../../food"
import { Building, Item, Station } from "../../types"

export const HuntingLodgeResources: Item[] = [Perch, Roach]

const Workbench: Station = {
  name: "Workbench",
  recipes: [FishMeat]
}

export const DryingRack: Station = {
  name: "Drying Rack",
  recipes: [DriedFishMeat, DriedMeat]
}

const FishingHut: Building = {
  name: "Fishing Hut",
  stations: [DryingRack, Workbench],
  items: HuntingLodgeResources,
}

export default FishingHut