import { Meat } from "../../food"
import { Building, Item, Station } from "../../types"
import { DryingRack } from "./fishingHut"
import { Bow, Longbow, RecurveBow } from "../../tools"
import { Leather, Fur, Feather } from "../../resources/animal"

export const HuntingLodgeItems: Item[] = [Leather, Fur, Feather, Meat,]

const Workbench: Station = {
  name: "Workbench",
  recipes: [
    Bow,
    Longbow,
    RecurveBow,
  ]
}

const HuntingLodge: Building = {
  name: "Hunting Lodge",
  stations: [
    Workbench,
    DryingRack,
  ],
  items: HuntingLodgeItems,
}

export default HuntingLodge