import { ReactNode } from "react"
import IconCoin from '../assets/coin.png'

const Coin = ({
  children
}: {
  children: ReactNode
}) => (
  <div className="inline-flex space-x-1 items-center">
    <span>{children}</span>
    <img src={IconCoin} className="h-5 w-auto transform -translate-y-0.5" alt="Coin Icon" />
  </div>
)

export default Coin