import { useEffect, useState } from 'react';
import { buildings } from './data/buildings';
import './styles/main.scss';
import { Building, Category, Data, Item, Station, defaultData } from './data/types';
import ItemTable from './components/ItemTable';
import BubbleRow, { Bubble } from './components/BubbleRow';
import { SkillBarter } from './data/skills';

function App() {
  const [data, setData] = useState<Data>(defaultData)
  const loadedData = localStorage.getItem("MDdata")

  useEffect(() => {
    localStorage.setItem("MDdata", JSON.stringify(data))
  }, [data])

  useEffect(() => {
    if (loadedData) setData(JSON.parse(loadedData))
    else setData(defaultData)
    // eslint-disable-next-line
  }, [])

  const hideCurrent = () => {
    const d = { ...data }
    d.station = undefined
    d.category = undefined
    d.item = undefined
    d.items = undefined
    setData(d)
  }

  const addToComparison = (item: Item) => {
    const d = { ...data }
    let c = d.comparisonItems
    c?.includes(item) ? d.comparisonItems = d.comparisonItems?.filter(i => i.name !== item.name) : c?.push(item)
    setData(d)
  }

  const handleBarter = (level: number) => {
    const d = { ...data }
    d.barter = level
    setData(d)
  }

  const handleBuilding = (building: Building) => {
    const d = { ...data }
    hideCurrent()
    d.building = building
    setData(d)
  }

  const handleStation = (station: Station) => {
    const d = { ...data }
    hideCurrent()
    d.station = station
    setData(d)
  }

  const handleItems = (items?: Item[]) => {
    const d = { ...data }
    hideCurrent()
    d.items = items
    setData(d)
  }

  const handleCategory = (category: Category) => {
    const d = { ...data }
    hideCurrent()
    d.category = category
    setData(d)
  }

  const handleItem = (item: Item) => {
    const d = { ...data }
    hideCurrent()
    d.item = item
    setData(d)
  }

  const handleCompactComparison = () => {
    const d = { ...data }
    d.compactComparison = !d.compactComparison
    setData(d)
  }

  return (
    <div className="p-4 h-full">
      <div className="container max-w-screen-lg mx-auto h-full flex gap-2 bg-background">
        <div className="flex-shrink-0 w-72 h-full bg-surface p-4 border-fancy overflow-y-auto scrollbar-thin scrollbar-thumb-primary">
          <BubbleRow label="Barter Skill Level">
            {SkillBarter.levels.map((level, i) => <Bubble key={level} label={`${i} (${level}%)`} active={level === data.barter} onClick={() => handleBarter(level)} />)}
          </BubbleRow>

          <BubbleRow label="Building">
            {buildings.map(building => <Bubble key={building.name} active={data.building === building} label={building.name} onClick={() => handleBuilding(building)} />)}
          </BubbleRow>

          {data.building?.stations &&
            <BubbleRow label="Stations">
              {data.building?.stations?.map(station =>
                <Bubble
                  key={station.name}
                  active={data.station === station}
                  label={station.name}
                  onClick={() => handleStation(station)}
                />
              )}
              {data.building?.items &&
                <Bubble
                  key={data.building.name}
                  active={data.items === data.building.items}
                  label="Items" onClick={() => handleItems(data.building?.items)}
                />
              }
            </BubbleRow>
          }

          {data.station?.categories &&
            <BubbleRow label="Categories">
              {data.station?.categories.map(category =>
                <Bubble
                  key={category.name}
                  active={data.category === category}
                  label={category.name}
                  onClick={() => handleCategory(category)}
                />
              )}
            </BubbleRow>
          }
          {data.category?.recipes &&
            <BubbleRow label="Recipes">
              {data.category?.recipes.map(recipe => <Bubble key={recipe.name} active={data.item === recipe} label={recipe.name} onClick={() => handleItem(recipe)} />)}
            </BubbleRow>}

          {data.station?.recipes &&
            <BubbleRow label="Recipes">
              {data.station?.recipes?.map(recipe => <Bubble key={recipe.name} active={data.item === recipe} label={recipe.name} onClick={() => handleItem(recipe)} />)}
            </BubbleRow>}

          {data.items &&
            <div className="flex-1 overflow-y-auto">
              <BubbleRow label="Items">
                {data.items?.map(item => <Bubble key={item.name} active={data.item === item} label={item.name} onClick={() => handleItem(item)} />)}
              </BubbleRow>
            </div>
          }

        </div>
        <div className="flex flex-col flex-1 border-fancy">


          {data.item && <ItemTable item={data.item} barter={data.barter} addComparison={addToComparison} comparisonItems={data.comparisonItems} />}

          {data.comparisonItems && data.comparisonItems.length > 0 && (
            <>
              <label className="flex gap-2 items-center mt-8 mb-2 cursor-pointer" onClick={() => handleCompactComparison()}>
                <div className={`w-4 h-4 border border-primary rounded-sm ${data.compactComparison ? "bg-primary" : ""}`} />
                <div className="">compact</div>
              </label>
              <div className="grid grid-cols-2 gap-1 overflow-y-auto scrollbar-thin scrollbar-thumb-primary">
                {data.comparisonItems?.map((item, i) => <ItemTable key={i} compact={data.compactComparison} item={item} barter={data.barter} addComparison={addToComparison} comparisonItems={data.comparisonItems} />)}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;