import { Fur, Leather } from "./resources/animal";
import { Straw } from "./resources/resources";
import { WoolFabric, LinenFabric, LinenThread, WoolThread } from "./resources/sewing";
import { TinBar } from "./resources/smithing";
import { Category, Item } from "./types";

// HATS

export const Hat: Item = {
  name: "Hat",
  price: 290,
  weight: 0.1,
  time: 10000,
  technology: { tech: "Production", amount: 750 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 1 },
    { item: LinenFabric, amount: 1 },
    { item: LinenThread, amount: 2, }
  ],
  storage: "Resource Storage",
}

export const BagHat: Item = {
  name: "Bag Hat",
  price: 720,
  weight: 0.2,
  time: 20000,
  technology: { tech: "Production", amount: 7500 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 2 },
    { item: WoolThread, amount: 1 },
    { item: LinenFabric, amount: 2, }
  ],
  storage: "Resource Storage",
}

export const FeltHat: Item = {
  name: "Felt Hat",
  price: 650,
  weight: 0.15,
  time: 17000,
  technology: { tech: "Production", amount: 7500 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 2 },
    { item: WoolThread, amount: 1 },
    { item: LinenFabric, amount: 2, }
  ],
  storage: "Resource Storage",
}

export const HatWithLapels: Item = {
  name: "Hat with Lapels",
  price: 420,
  weight: 0.1,
  time: 15000,
  technology: { tech: "Production", amount: 7500 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 1 },
    { item: WoolThread, amount: 1 },
    { item: LinenFabric, amount: 1, }
  ],
  storage: "Resource Storage",
}

export const StrawHat: Item = {
  name: "Straw Hat",
  price: 50,
  weight: 0.2,
  time: 5000,
  scheme: 50,
  durability: 100,
  recipe: [
    { item: Straw, amount: 20 },
    { item: LinenFabric, amount: 1, }
  ],
  storage: "Resource Storage",
}

export const FlatStrawHat: Item = {
  name: "Flat Straw Hat",
  price: 60,
  weight: 0.15,
  time: 5000,
  scheme: 100,
  durability: 100,
  recipe: [
    { item: Straw, amount: 30 },
    { item: LinenFabric, amount: 1, }
  ],
  storage: "Resource Storage",
}

export const Coif: Item = {
  name: "Coif",
  price: 80,
  weight: 0.05,
  time: 5000,
  scheme: 100,
  durability: 100,
  recipe: [
    { item: LinenThread, amount: 1 },
    { item: LinenFabric, amount: 1, }
  ],
  storage: "Resource Storage",
}

export const Cap: Item = {
  name: "Cap",
  price: 140,
  weight: 0.05,
  time: 10000,
  technology: { tech: "Production", amount: 750 },
  durability: 100,
  recipe: [
    { item: LinenFabric, amount: 2, },
    { item: LinenThread, amount: 1 },
  ],
  storage: "Resource Storage",
}

export const CapWithCoif: Item = {
  name: "Cap with Coif",
  price: 270,
  weight: 0.1,
  time: 10000,
  technology: { tech: "Production", amount: 750 },
  durability: 100,
  recipe: [
    { item: LinenFabric, amount: 4, },
    { item: LinenThread, amount: 2 },
  ],
  storage: "Resource Storage",
}

export const CategoryHats: Category = {
  name: "Hats",
  recipes: [Hat, BagHat, FeltHat, HatWithLapels, StrawHat, FlatStrawHat, Coif, Cap, CapWithCoif,],
}


// HOODS

export const Hood: Item = {
  name: "Hood",
  price: 410,
  weight: 0.3,
  time: 15000,
  technology: { tech: "Production", amount: 750 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 2 },
  ],
  storage: "Resource Storage",
}

export const FurHood: Item = {
  name: "Fur Hood",
  price: 440,
  weight: 0.35,
  time: 16000,
  technology: { tech: "Production", amount: 750 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 2 },
    { item: Fur, amount: 4 },
  ],
  storage: "Resource Storage",
}

export const LongFurHood: Item = {
  name: "Long Fur Hood",
  price: 740,
  weight: 0.4,
  time: 18000,
  technology: { tech: "Production", amount: 7500 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 3 },
    { item: Fur, amount: 6 },
  ],
  storage: "Resource Storage",
}

export const LongHood: Item = {
  name: "Long Hood",
  price: 690,
  weight: 0.4,
  time: 17000,
  technology: { tech: "Production", amount: 7500 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 3 },
  ],
  storage: "Resource Storage",
}

export const FurCapelet: Item = {
  name: "Fur Capelet",
  price: 140,
  weight: 0.25,
  time: 10000,
  scheme: 300,
  durability: 100,
  recipe: [
    { item: Fur, amount: 12 },
    { item: LinenThread, amount: 2 },
  ],
  storage: "Resource Storage",
}

export const CategoryHoods: Category = {
  name: "Hoods",
  recipes: [Hood, FurHood, LongFurHood, LongHood, FurCapelet,],
}


// TUNICS

export const SimpleTunic: Item = {
  name: "Simple Tunic",
  price: 320,
  weight: 0.5,
  time: 11000,
  technology: { tech: "Production", amount: 750 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 1 },
    { item: Leather, amount: 2 },
    { item: LinenThread, amount: 3 },
  ],
  storage: "Resource Storage",
}

export const ShortSleeveTunic: Item = {
  name: "Short Sleeve Tunic",
  price: 240,
  weight: 0.45,
  time: 10000,
  technology: { tech: "Production", amount: 750 },
  durability: 100,
  recipe: [
    { item: LinenFabric, amount: 3 },
    { item: Leather, amount: 2 },
    { item: LinenThread, amount: 2 },
  ],
  storage: "Resource Storage",
}

export const Tunic: Item = {
  name: "Tunic",
  price: 510,
  weight: 0.5,
  time: 16000,
  technology: { tech: "Production", amount: 750 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 2 },
    { item: Leather, amount: 2 },
    { item: LinenThread, amount: 2 },
  ],
  storage: "Resource Storage",
}

export const FeltVest: Item = {
  name: "Felt Vest",
  price: 510,
  weight: 0.5,
  time: 18000,
  technology: { tech: "Production", amount: 7500 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 2 },
    { item: Leather, amount: 2 },
    { item: WoolThread, amount: 2 },
  ],
  storage: "Resource Storage",
}

export const QuiltedVest: Item = {
  name: "Quilted Vest",
  price: 800,
  weight: 0.8,
  time: 20000,
  technology: { tech: "Production", amount: 7500 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 3 },
    { item: Leather, amount: 2 },
    { item: LinenThread, amount: 2 },
  ],
  storage: "Resource Storage",
}

export const CategoryTunics: Category = {
  name: "Tunics",
  recipes: [SimpleTunic, ShortSleeveTunic, Tunic, FeltVest, QuiltedVest],
}


// SHIRTS

export const SimpleLinenShirt: Item = {
  name: "Simple Linen Shirt",
  price: 110,
  weight: 0.25,
  time: 5000,
  scheme: 200,
  durability: 100,
  recipe: [
    { item: LinenFabric, amount: 1 },
    { item: LinenThread, amount: 2 },
  ],
  storage: "Resource Storage",
}

export const LinenShirt: Item = {
  name: "Linen Shirt",
  price: 190,
  weight: 0.25,
  time: 10000,
  scheme: 250,
  durability: 100,
  recipe: [
    { item: LinenFabric, amount: 2 },
    { item: LinenThread, amount: 3 },
  ],
  storage: "Resource Storage",
}

export const CategoryShirts: Category = {
  name: "Shirts",
  recipes: [SimpleLinenShirt, LinenShirt],
}


// TROUSERS

export const Trousers: Item = {
  name: "Trousers",
  price: 320,
  scheme: 200,
  weight: 0.3,
  time: 3000,
  durability: 100,
  recipe: [
    { item: LinenFabric, amount: 1 },
    { item: LinenThread, amount: 2, }
  ],
  storage: "Resource Storage",
}

export const TrousersWithCuffs: Item = {
  name: "Trousers with Cuffs",
  price: 510,
  scheme: 250,
  weight: 0.35,
  time: 5000,
  durability: 100,
  recipe: [
    { item: LinenFabric, amount: 2 },
    { item: Leather, amount: 2, },
    { item: LinenThread, amount: 2, }
  ],
  storage: "Resource Storage",
}

export const Hose: Item = {
  name: "Hose",
  price: 670,
  weight: 0.4,
  time: 10000,
  technology: { tech: "Production", amount: 750 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 1 },
    { item: LinenFabric, amount: 1 },
    { item: LinenThread, amount: 2, }
  ],
  storage: "Resource Storage",
}

export const JoinedHose: Item = {
  name: "Joined Hose",
  price: 920,
  weight: 0.5,
  time: 15000,
  technology: { tech: "Production", amount: 750 },
  durability: 100,
  recipe: [
    { item: WoolFabric, amount: 2 },
    { item: LinenFabric, amount: 1 },
    { item: LinenThread, amount: 1, }
  ],
  storage: "Resource Storage",
}

export const CategoryTrousers: Category = {
  name: "Trousers",
  recipes: [Trousers, TrousersWithCuffs, Hose, JoinedHose],
}


// SHOES

export const SmallSaddlebag: Item = {
  name: "Small Saddlebag",
  price: 250,
  weight: 2,
  time: 6000,
  durability: 100,
  recipe: [
    { item: Leather, amount: 10 },
    { item: TinBar, amount: 1 },
    { item: LinenThread, amount: 2, }
  ],
  storage: "Resource Storage",
}

export const CategoryMount: Category = {
  name: "Mount",
  recipes: [SmallSaddlebag]
}