import { Item } from "./types";

export const Apple: Item = {
  name: "Apple",
  price: 4,
  weight: 0.09,
}

export const Meat: Item = {
  name: "Meat",
  price: 4,
  weight: 0.2,
}

export const DriedMeat: Item = {
  name: "Dried Meat",
  price: 8,
  weight: 0.2,
  time: 5000,
  recipe: [{
    item: Meat,
    amount: 1,
  }]
}

export const Perch: Item = {
  name: "Perch",
  price: 10,
  weight: 0.8,
}

export const Roach: Item = {
  name: "Roach",
  price: 3,
  weight: 0.2,
}

export const FishMeat: Item = {
  name: "Fish Meat",
  price: 10,
  weight: 0.2,
}
export const DriedFishMeat: Item = {
  name: "Dried Fish Meat",
  price: 20,
  weight: 0.2,
  time: 5000,
  recipe: [{
    item: FishMeat,
    amount: 1,
  }]
}