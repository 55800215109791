import { ReactNode, useEffect, useState } from "react"
import Coin from "./Coin"
import React from "react"
import { Tooltip } from "react-tooltip"
import { Item } from "../data/types"
import { barterPrice } from "../helper"
import { Star } from "react-feather"

const ItemTable = ({
  item,
  barter,
  addComparison,
  comparisonItems,
  compact = false,
}: {
  item: Item
  barter: number
  addComparison: (item: Item) => void
  comparisonItems?: Item[]
  compact?: boolean
}) => {

  const [totalPrice, setTotalPrice] = useState<number>()

  const containsItem = comparisonItems?.includes(item)

  useEffect(() => {
    let sum = 0
    item?.recipe?.map(rItem => sum += rItem.amount * rItem.item.price)
    setTotalPrice(sum)
  }, [item])

  return (
    <div className={`
      item-table p-4 ${compact ? "text-sm" : ""} relative pr-8 cursor-pointer bg-item border border-primary hover:bg-opacity-50 transition-colors duration-300`} onClick={() => addComparison(item)}>
      <div className="absolute top-2 right-2">
        <Star size={16} className={`cursor-pointer transform hover:scale-110 active:scale-95 transition-all duration-300 stroke-primary ${containsItem ? "fill-primary" : ""}`} />
      </div>
      <table>
        <tbody>
          <TableRow >{item.name}</TableRow>
          {!compact &&
            <>
              <TableRow label="Recipe (Worth)">
                {item.recipe ?
                  <ul>
                    {item.recipe?.map(rItem => {
                      return (
                        <React.Fragment key={rItem.item.name}>
                          <li className="">
                            <div>{rItem.amount}x <span id={rItem.item.name.split(' ').join('')} className={rItem.item.recipe && "text-primary"}>{rItem.item?.name}</span> (<Coin>{rItem.amount * barterPrice(barter, rItem.item?.price)}</Coin>)</div>
                          </li>
                          {rItem.item.recipe && (
                            <Tooltip anchorSelect={"#" + rItem.item.name.split(' ').join('')}>
                              <ul>
                                {rItem.item.recipe?.map(rItem => {
                                  return (<React.Fragment key={rItem.item.name}>
                                    <li className="">
                                      <div>{rItem.amount}x {rItem.item?.name} (<Coin>{rItem.amount * barterPrice(barter, rItem.item?.price)}</Coin>)</div>
                                    </li>
                                  </React.Fragment>
                                  )
                                })}
                              </ul>
                            </Tooltip>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </ul>
                  : <span>-</span>
                }
              </TableRow>
              <TableRow label="Recipe Worth">
                {totalPrice ? <Coin>{totalPrice}</Coin> : "-"}
              </TableRow>
            </>
          }
          <TableRow label="Selling Price"><Coin>{barterPrice(barter, item.price)}</Coin></TableRow>
          {totalPrice != null && <TableRow label="Profit"><Coin><span className="font-bold text-primary">{barterPrice(barter, item.price) - totalPrice}</span></Coin></TableRow>}
        </tbody>
      </table>
    </div>
  )
}

export default ItemTable

const TableRow = ({
  label,
  children,
}: {
  label?: string
  children?: ReactNode
}) => {
  return (
    <tr className="align-top">
      {label && <td colSpan={label ? 1 : 2} className="pr-4 text-sm pb-1">{label}</td>}
      {children && <td className={`pb-1 ${label ? "" : "text-primary text-lg"}`}>{children}</td>}
    </tr>
  )
}