import { ReactNode } from "react"
import Headline from "./Headline"

const BubbleRow = ({
  label,
  children
}: {
  label?: string
  children: ReactNode
}) => {
  return (
    <div className="pb-4">
      {label && <Headline headline={label} />}
      <div className="flex flex-col gap-x-4 gap-y-2 flex-wrap">
        {children}
      </div>
    </div>
  )
}

export default BubbleRow

export const Bubble = ({
  label,
  onClick,
  active = false,
}: {
  label: string
  onClick: () => void
  active: boolean
}) => {
  return (
    <div className={`inline-flex text-sm   ${active ? "font-bold pointer-events-none" : "cursor-pointer"}`} onClick={onClick}>{label}</div>
  )
}