import { SmithingItem } from "../types"

export const CopperOre: SmithingItem = {
  name: "Copper Ore",
  price: 8,
  weight: 1,
  storage: "Resource Storage",
}

export const CopperBar: SmithingItem = {
  name: "Copper Bar",
  price: 27,
  weight: 1,
  storage: "Resource Storage",
  recipe: [{
    item: CopperOre,
    amount: 2,
  }],
  time: 2000,
}

export const TinOre: SmithingItem = {
  name: "Tin Ore",
  price: 10,
  weight: 1,
  storage: "Resource Storage",
}

export const TinBar: SmithingItem = {
  name: "Tin Bar",
  price: 40,
  weight: 1,
  storage: "Resource Storage",
  recipe: [{
    item: TinOre,
    amount: 2,
  }],
  time: 1000,
}

export const BronzeBar: SmithingItem = {
  name: "Bronze Bar",
  price: 90,
  weight: 2,
  storage: "Resource Storage",
  recipe: [{
    item: CopperBar,
    amount: 1,
  }, {
    item: TinBar,
    amount: 1,
  },],
  time: 1000,
}

export const IronOre: SmithingItem = {
  name: "Iron Ore",
  price: 15,
  weight: 1,
  storage: "Resource Storage",
}

export const IronBar: SmithingItem = {
  name: "Iron Bar",
  price: 60,
  weight: 1,
  storage: "Resource Storage",
  recipe: [{
    item: IronOre,
    amount: 2,
  },],
  time: 3000,
}