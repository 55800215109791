import { Item } from "../types"

// GATHER

export const Log: Item = {
  name: "Log",
  price: 1,
  weight: 2,
  storage: "Resource Storage",
}

export const Stick: Item = {
  name: "Stick",
  price: 0.3,
  weight: 0.1,
  storage: "Resource Storage",
}

export const Stone: Item = {
  name: "Stone",
  price: 0.4,
  weight: 1,
  storage: "Resource Storage",
}

export const Clay: Item = {
  name: "Clay",
  price: 0.4,
  weight: 0.5,
  storage: "Resource Storage",
}

export const Limestone: Item = {
  name: "Limestone",
  price: 0.2,
  weight: 1,
  storage: "Resource Storage",
}

export const Straw: Item = {
  name: "Straw",
  price: 0.1,
  weight: 0.04,
  storage: "Resource Storage",
}